<template>
  <div class="car-identification">
    <Header :isHeader="true" :back="true" />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar />
      </template>
      <Title titleStyle="title" :text="translateString('identifyVehicle')" />
      <CarInfo :justMobile="true" />
      <div class="checkbox-container">
        <CheckboxContainer
          v-for="combo in carCombo"
          :key="combo.typeVehicleId"
          :id="'combo-' + combo.typeVehicleId"
          :label="combo.typeVehicleDesc"
          :value="combo.typeVehicleDesc"
          checkboxClass="checkbox-round"
          containerClass="right"
          inputType="radio"
          name="combo"
          @updateInput="updateInput($event)"
        />
      </div>
      <template v-slot:footer>
        <Footer>
          <Button
            :label="translateString('continue')"
            :btnClass="ctaBtn"
            @action="next()"
          />
        </Footer>
      </template>
    </Wrapper>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import CarInfo from "@/components/UpdatedResume/CarInfo.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Title from "@/components/Title/Title.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";

import { translateString } from "@/functions/functions.js";

export default {
  name: "CarIdentification",
  components: {
    Button,
    CarInfo,
    CheckboxContainer,
    Footer,
    Header,
    Sidebar,
    Title,
    Wrapper,
  },
  data() {
    return {
      ctaBtn: "disabled ",
      carName: this.$store.state.carName,
      carData: this.$store.state.carByPlate,
      carCombo: this.$store.state.carCombo,
      comboLabel: "",
      comboId: "",
      comboSelected: [],
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
  },
  created() {
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }
  },
  methods: {
    translateString,
    updateInput(value) {
      this.comboSelected = {
        comboLabel: value.value,
        comboId: value.id.replace("combo-", ""),
        comboSelectedByUser: true,
      };

      this.$store.dispatch("selectOption", {
        selectedValue: "carComboSelected",
        value: this.comboSelected,
      });
    },
    next() {
      console.log(this.$store.state.firstPath);
      this.$router.push({ name: "CarConfirmation" });
    },
  },
  watch: {
    comboSelected() {
      if (this.combo != "") {
        this.ctaBtn = "";
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
